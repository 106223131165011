.container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-items: center;
	padding:10px;
}

.content {
	display:flex;
	align-items:center;
	justify-content: space-evenly;
}

.content-piece img {
	max-width: 50%;
}

.wallet-option-list {
	list-style: none;
}

.wallet-option-list li {
	margin: 1em;
}
.wallet-option {
	display: flex;
	align-items: center;
}
.wallet-branding {
	width: 2em;
	margin-right:0.5em
}

.content-info ul {
	list-style-type: none;
}

.content-info li {
	margin:2px;
}

.mint-card {
	min-width: 50%;
}

.mint-card .bp3-input-group {
	margin:5px;
}
.mint-card .bp3-form-group {
	width: 90%;
}
.mint-card textarea {
	width: 100%
}

.content-collection {
	flex-direction:row;
	flex-wrap:wrap	;
}
.content-collection-item {
	width: 30em;
	margin: 2em;
}
.content-collection-item img {
	max-width: 100%;
}

.extra-prop-list {
	list-style: none;
	width:100%;
}

.extra-prop-item {
	width: 100%;
	overflow: auto;
	padding:0.1em;
}

.extra-prop-item input {
	max-width: 40%;
	margin: 2%;
	float:left;
}
.extra-prop-collapse p {
	text-align: center;
}

.extra-prop-add {
	justify-self: right;
}
.extra-prop-dropdown {
	flex-direction: column;
}

.extra-param-dropdown {
	flex-direction: column;
}

.extra-param-list {
	flex-flow: column;
	align-items: flex-end;
}

.extra-param-list .bp3-form-group {
	align-items: center;
}

.extra-prop-collapse {
	margin:10px;
	width: 100%;
}

.raw-metadata {
	max-width: 100%
}

.container-mint {
	justify-content: flex-end;
}

.media-display {
	padding: 10px;
}